import React, { Fragment, useState, useEffect, useRef } from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { useForm } from 'react-hook-form'
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom'
import Select from 'react-select'
import one from '../assets/images/job-search/1.jpg';
import {
    Container, Row, Col, Card, CardBody, CardHeader, Media, Button, Form, FormGroup, Label,
    Input, Modal, ModalHeader, ModalBody,  InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap'
import { Cancel } from "../constant";
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify';
import { ToastWarn } from '../components/notifications/Notification'
import { tableData } from '../data/dummyTableData'
import { postWithData, get, postWithDataMultiform, postWithDataMultiformFile } from '../services/api'
import TechnologyTypeahead from './technology-typeahead';
import '../assets/css/toastStyle.css'; // Importa el archivo CSS con los estilos del toast

const AddCandidate = (props) => {

    // eslint-disable-next-line
    const { register, handleSubmit, errors } = useForm();
    const [data, setData] = useState(tableData)
    const openModalAddCandidate = () => setVisibleModalAddCandidate(!visibleModalAddCandidate);
    const openModalResultSearch = () => setVisibleModalResultSearch(!visibleModalResultSearch);

    const [visibleModalAddCandidate, setVisibleModalAddCandidate] = useState(false);
    const [visibleAddPosition, setVisibleAddPosition] = useState(false);
    const [visibleModalResultCandidateSearch, setVisibleModalResultCandidateSearch] = useState(false);
    const [visibleModalResultSearch, setVisibleModalResultSearch] = useState(null);
    const [dataAdd, setDataAdd] = useState({})
    const [dataResultSearch, setDataResultSearch] = useState([])
    const [dataResultCandidateSearch, setDataResultCandidateSearch] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [idUserSesion, setIdUserSesion] = useState(localStorage.getItem('Id'));

    const [checkSavePhone, setCheckSavePhone] = useState(true);
    const [checkSaveDNI, setCheckSaveDNI] = useState(true);
    const [checkSaveEmail, setCheckSaveEmail] = useState(true);


    const [listPositionData, setListPositionData] = useState([]);
    const [listPositionDataTable, setListPositionDataTable] = useState([]);

    const [listLanguageData, setListLanguageData] = useState([]);
    const [listLanguageDataShow, setListLanguageDataShow] = useState([]);
    const [listOriginShow, setListOriginShow] = useState([]);
    const [listMainMarketShow, setListMainMarketShow] = useState([]);

    const [multiplePosition, setMultiplePosition] = useState([]);
    const [multipleTec, setMultipleTec] = useState([]);
    const [multipleCategory, setMultipleCategory] = useState([]);
    const [multipleGrade, setMultipleGrade] = useState([]);
    const [multipleFormation, setMultipleFormation] = useState([]);
    const [multipleLang, setMultipleLang] = useState([]);
    const [multipleOrigin, setMultipleOrigin] = useState([]);
    const [multipleMainMarket, setMultipleMainMarket] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [candiateSearch, setCandiateSearch] = useState({});
    const [salary, setSalary] = useState('')

    const [listClientsData, setListClientsData] = useState([]);
    
    const [listClientsPortalData, setListClientsPortalData] = useState([]);

    const [positionName, setPositionName] = useState('');
    const [positionCode, setPositionCode] = useState('');
    const [clientValue, setClientValue] = useState('');
    const [selectedOptionsPosition, setSelectedOptionsPosition] = useState([]);
    const typeaheadRef = useRef(null);

    const [listAcademic, setListAcademic] = useState([]);
    const [listAcademicResev, setListAcademicReserv] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        getListLanguages();
        getListPosition();
        getListOrigin();
        getListClients();
        getListAcademic();
        
    }, [0])

    const onSubmit = (data, e) => {
        e.preventDefault();
        
        let lenguages = [];
        console.log(checkSavePhone, checkSaveDNI, checkSaveEmail);
        
        if(checkSavePhone == true && checkSaveDNI == true && checkSaveEmail == true){
            setDisabledButton(true);
            console.log("SI PUEDE GUARDAR");
            
            if (multipleLang.length > 0) {
                multipleLang.forEach(item => {
                    let foundLang = listLanguageData.find(function (element) { return element.name == item });
                    lenguages.push(foundLang.id)
                });
            }

            let dataComp = {
                ...data,
                position: multiplePosition.join(),
                technologies: multipleTec.join(),
                languages: lenguages.join(),
                grade: multipleGrade,
                formation: multipleFormation,
                category: multipleCategory,
                origin: multipleOrigin,
                //main_market: multipleMainMarket,
                // is_portal: isChecked,  
                date_brith: startDate,
                //fake user  id in dev mode
                user_id: localStorage.getItem('Id')
            }

            if (data !== '') {
                setDataAdd(dataComp);
                postWithDataMultiformFile("api/candidate/add", dataComp).then((result) => {
                    //console.log(result);
                    if (result.success == true) {
                        e.target.reset();
                        toast.success("Candidato agregado de forma satisfactoria!");
                        window.location.href = "/candidate/history-candidate/" + result.data;
                    }
                })
                .finally(()=>setDisabledButton(false));
            } else {
                errors.showMessages();
                setDisabledButton(false);
            }
            
        
        }else{
            if(checkSavePhone == false){
                console.log("NO PUEDE GUARDAR::::", checkSavePhone);
                toast.error('No es posible agregar este candidato debido a que ya existe una coincidencia guardada con el NUMERO DE TELEFONO', 
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 10000, // 5 segundos
                    className: 'larger-toast' // Clase CSS para el estilo del toast
                });

            }
            
            if(checkSaveDNI == false){
                console.log("NO PUEDE GUARDAR::::", checkSaveDNI);
                toast.error('No es posible agregar este candidato debido a que ya existe una coincidencia guardada con el NUMERO DE IDENTIFICACION', 
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 10000, // 5 segundos
                    className: 'larger-toast' // Clase CSS para el estilo del toast
                });

            }
            
            if(checkSaveEmail == false){
                console.log("NO PUEDE GUARDAR::::", checkSaveEmail);
                toast.error('No es posible agregar este candidato debido a que ya existe una coincidencia guardada con el CORREO ELECTRONICO', 
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 10000, // 5 segundos
                    className: 'larger-toast' // Clase CSS para el estilo del toast
                });

            }
        }
    };

    const CustomToastWithInformation = (message, setVisible) => (
        <div>
            <p>
                {message}
                {". Para mostrar coincidencias haga "}<span onClick={() => {
                    setVisible(true)
                }}>{"click aqui!"}</span>
            </p>

        </div>
    );

    const handleChangeInputSearch = (data) => {
        let stringValue = data.target.value;
        //let array = [];
        let name = data.target.name
        let obj = [];
        obj[name] = stringValue;

        //console.log(obj);

        if (stringValue.length >= 3) {
            searchPosition(obj, data.target.name);
        }
    };

    const searchPosition = async (data, fromShearch) => {
        //setVisibleModalResultSearch(!visibleModalResultSearch)
        let dataListSearch = [];
        let labelInformaticion = '';
        //console.log(fromShearch);

        switch (fromShearch) {
            case 'code_position':
                labelInformaticion = 'el Código de la Vacante'
                dataListSearch = listPositionData.filter((item) => item.code_position && item.code_position.toLowerCase().includes((data.code_position).toLowerCase())); //item.code_position && item.code_position.toLowerCase() para que no de error si es null

                break;
            // case 'name_position':
            //     labelInformaticion = 'el Nombre de la Vacante'
            //     dataListSearch = listPositionData.filter((item) => item.name && item.name.toLowerCase().includes((data.name_position).toLowerCase()));
            //     break;
            default:
                break;
        }

        let countResult = dataListSearch.length;
        //console.log(countResult);
        if (countResult != 0) {
            setDataResultSearch(dataListSearch)
           //console.log ("Se encontraron " + countResult + " coincidencias con " + labelInformaticion + "");
            ToastWarn(CustomToastWithInformation("Se encontraron " + countResult + " coincidencias con " + labelInformaticion + "", setVisibleModalResultSearch))
        } else {
            toast.success(("No se encontraron coincidencias con " + labelInformaticion + ""))
        }
    }

    const getListLanguages = async () => {
        let dataRes = [];
        try {
            get("api/language/list", data).then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push(element.name)
                    });
                    setListLanguageData(result.data)
                    setListLanguageDataShow(dataRes)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListPosition = () => {
        let dataRes = [];
        try {
            get("api/position/listclean", data).then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        let item = {
                            'id' : element.id,
                            'name' : element.name+' - ('+element.name_client+')'
                        }
                        dataRes.push(item)
                        
                    });
                    //console.log(result.data);
                    setListPositionData(dataRes)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }
    const getListAcademic = () => {
        let dataRes = [];
        try {
            get("api/academic/list", data).then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push({value: element.id, label: element.academic_type})
                    });
                    setListAcademic(dataRes);
                    setListAcademicReserv(dataRes);
                   
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListOrigin = async () => {
        try {
            let dataListOriginShow = [];
            get('api/origin/list').then((result) => {
                if (result.data.length > 0) {
                    result.data.forEach(element => {
                        let item = { value: element.id, label: element.name };
                        dataListOriginShow.push(item);
                    });
                    setListOriginShow(dataListOriginShow);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListMainMarket = async () => {
        try {
            let dataListMainMarketShow = [];
            get('api/mainmarket/list').then((result) => {
                if (result.data.length > 0) {
                    result.data.forEach(element => {
                        let item = { value: element.id, label: element.name };
                        dataListMainMarketShow.push(item);
                    });
                    setListMainMarketShow(dataListMainMarketShow);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectOrigin = (data) => {
        setMultipleOrigin(data.value)
    };
    /*
    const handleSelectMainMarket = (data) => {
        setMultipleMainMarket(data.value)
    };*/

    const handleSelectPosition = (selected) => {
        setMultiplePosition(selected.map(item => item.id));
        //console.log(multiplePosition);
    };

    const handleSelectCategory = (data) => {
        setMultipleCategory(data.value)
    };

    const handleSelectLang = (data) => {
        setMultipleLang(data)
    };


    const handleSelectGrade = (data) => {
        setMultipleGrade(data.value)
    };

    const handleSelectForma = (data) => {
        setMultipleFormation(data.value)
        //console.log(multipleFormation);
    };

    const handleChange = date => {
        setStartDate(date)
    };

    const handleTechnologySelection = (selectedOption) => {
        setMultipleTec(selectedOption);
    };

    const handleEducationChange = (inputValue) => {
        setListAcademic(listAcademicResev);
        setListAcademic((prevList) => [...prevList, { value: inputValue, label: inputValue }]);
    }

    // const handleRowSelected = useCallback(state => {
    //     setSelectedRows(state.selectedRows);
    // }, []);

    // const contextActions = useMemo(() => {
    //     const handleDelete = () => {

    //         if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
    //             setToggleCleared(!toggleCleared);
    //             setData(differenceBy(data, selectedRows, 'name'));
    //             toast.success("Successfully Deleted !")
    //         }
    //     };

    //     return <button key="delete" className="btn btn-danger" onClick={handleDelete}>Delete</button>;
    // }, [data, selectedRows, toggleCleared]);

    const handleToggleVisibleAddPosition = () => {
        setVisibleAddPosition(!visibleAddPosition);
    }

    const getListClients = async () => {
        let dataRes = [];
        let dataResPortal = [];
        try {
            get("api/client/list").then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push({ value: element.id, label: element.name })
                        //Filtra los portales
                        if ([1,18,17,19,22].indexOf(element.id) !== -1) {
                            dataResPortal.push({ value: element.id, label: element.name })
                        }
                    });
                    setListClientsData(dataRes)
                    setListClientsPortalData(dataResPortal)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }


    const handleAddPosition = () => {
        if (clientValue !== '' && positionCode !== '' && positionName !== '') {
            let dataPosition = {
                client_id: clientValue,
                code_position: positionCode,
                name: positionName,
            }
            setPositionCode('');
            setPositionName('');
            handleToggleVisibleAddPosition();

            postWithData("api/position/add_express", dataPosition).then((result) => {
                if (result.success === true) {
                    toast.success(`Se agregó una nueva vacante.`);
                    selectItemById(result.data);
                } else {
                    toast.warning('Hubo un problema al agregar nueva vacante.')
                }
            });
        }
    }


    const selectItemById = (positionId) => {
        getListPosition();
        setMultiplePosition((prevSelectedPosition) => [...prevSelectedPosition, positionId]);
    }

    const handleRowClick = (rowData) => {
        selectItemById(rowData.id);
        setVisibleModalResultSearch(false);
    }

    const tableColumnsSearch = [
        {
            name: 'Código',
            selector: 'code_position',
            sortable: false,
            center: false,
        },
        {
            name: 'Nombre',
            selector: 'name',
            sortable: false,
            center: false,
        },
        {
            name: 'Fecha de Ingreso',
            selector: 'created_at',
            sortable: false,
            center: false,
        },
    ]
    
    
    // CANDIDATE SEARCH ------------------------------------------------------------------------------------------------------------------------------------------------------------
    
    
    const handleChangeInputCandidateSearch = (data) => {
        let stringValue = data.target.value;
        //let array = [];
        let name = data.target.name
        let obj = [];
        obj[name] = stringValue;
        candiateSearch[name] = stringValue;

        if (stringValue.length >= 3) {
            //searchCandidate(candiateSearch, data.target.name);

            if(data.target.name == "phone"){
                searchCandidatePhone(candiateSearch, data.target.name);
            }

            if(data.target.name == "email"){
                searchCandidateEmail(candiateSearch, data.target.name);
            }

            if(data.target.name == "number_identity"){
                searchCandidateDNI(candiateSearch, data.target.name);
            }
            
            if(data.target.name != "phone" && data.target.name != "number_identity" && data.target.name != "email"){
                searchCandidate(candiateSearch, data.target.name);
            }
            
        }
    };

   const searchCandidate = async (data, fromShearch) => {
        try {
            //console.log(data)
           
            

            postWithDataMultiform("api/candidate/search", data).then((result) => {
                //console.log("RESPONSE ---------->", result)
                let countResult = result.data.length;


                if (countResult > 0) {

                    console.log(result.data)

                    let dataListSearch = [];
                    
                    result.data.forEach(element => {
                        let item = {
                            "id": element.id,
                            "type_identity": element.type_identity,
                            "number_identity": element.number_identity,
                            "name_complete": <Link to={`/candidate/history-candidate/${element.id}`}>{element.name + ' ' + element.lastname + ' ' + element.second_lastname}</Link>,
                            "phone": element.phone,
                            "email": element.email,
                        }
                        dataListSearch.push(item);
    
                    });
                    
                    setDataResultCandidateSearch(dataListSearch)
                    ToastWarn(CustomToastWithInformation("Se encontraron " + countResult + " coindencias", setVisibleModalResultCandidateSearch))
                    
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const searchCandidatePhone = async (data, fromShearch) => {
        try {
            //console.log(data)
            let searchPhone = data.phone ;
            let dataBusqueda = {
                    phone:data.phone
                }
            console.log(searchPhone)

            postWithDataMultiform("api/candidate/searchPhone", dataBusqueda).then((result) => {

                let countResult = result.data.length;

                if (countResult > 0) {

                    console.log(result.data)

                    let dataListSearch = [];
                    
                    result.data.forEach(element => {
                        let item = {
                            "id": element.id,
                            "type_identity": element.type_identity,
                            "number_identity": element.number_identity,
                            "name_complete": <Link to={`/candidate/history-candidate/${element.id}`}>{element.name + ' ' + element.lastname + ' ' + element.second_lastname}</Link>,
                            "phone": element.phone,
                            "email": element.email,
                        }
                        dataListSearch.push(item);

                        if(element.phone == searchPhone){
                            setCheckSavePhone(false);
                            console.log("No puede guardar por TELEFONO")
                        }
    
                    });
                    
                    setDataResultCandidateSearch(dataListSearch)
                    ToastWarn(CustomToastWithInformation("Se encontraron " + countResult + " coindencias", setVisibleModalResultCandidateSearch))
                    
                }else{
                    setCheckSavePhone(true);
                    console.log("SI puede guardar por TELEFONO")
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const searchCandidateEmail = async (data, fromShearch) => {
        try {
            //console.log(data)
            let searchEmail = data.email ;
            let dataBusqueda = {
                    email: data.email
                }
            console.log(searchEmail)

            postWithDataMultiform("api/candidate/searchEmail", dataBusqueda).then((result) => {

                let countResult = result.data.length;

                if (countResult > 0) {

                    console.log(result.data)

                    let dataListSearch = [];
                    
                    result.data.forEach(element => {
                        let item = {
                            "id": element.id,
                            "type_identity": element.type_identity,
                            "number_identity": element.number_identity,
                            "name_complete": <Link to={`/candidate/history-candidate/${element.id}`}>{element.name + ' ' + element.lastname + ' ' + element.second_lastname}</Link>,
                            "phone": element.phone,
                            "email": element.email,
                        }
                        dataListSearch.push(item);

                        if(element.email == searchEmail){
                            setCheckSaveEmail(false);
                            console.log("NO puede guardar por EMAIL")
                        }
    
                    });
                    
                    setDataResultCandidateSearch(dataListSearch)
                    ToastWarn(CustomToastWithInformation("Se encontraron " + countResult + " coindencias", setVisibleModalResultCandidateSearch))
                    
                }else{
                    setCheckSaveEmail(true);
                    console.log("SI puede guardar por EMAIL")
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const searchCandidateDNI = async (data, fromShearch) => {
        try {
            //console.log(data)
            let searchNumberIdentity = data.number_identity;
            let dataBusqueda = {
                number_identity: data.number_identity
                }
            console.log(searchNumberIdentity)

            postWithDataMultiform("api/candidate/searchDNI", dataBusqueda).then((result) => {

                let countResult = result.data.length;

                if (countResult > 0) {

                    console.log(result.data)

                    let dataListSearch = [];
                    
                    result.data.forEach(element => {
                        let item = {
                            "id": element.id,
                            "type_identity": element.type_identity,
                            "number_identity": element.number_identity,
                            "name_complete": <Link to={`/candidate/history-candidate/${element.id}`}>{element.name + ' ' + element.lastname + ' ' + element.second_lastname}</Link>,
                            "phone": element.phone,
                            "email": element.email,
                        }
                        dataListSearch.push(item);

                        if(element.number_identity == searchNumberIdentity){
                            setCheckSaveDNI(false);
                            console.log("No puede guardar por DNI")
                        }

                    });
                    
                    setDataResultCandidateSearch(dataListSearch)
                    ToastWarn(CustomToastWithInformation("Se encontraron " + countResult + " coindencias", setVisibleModalResultCandidateSearch))
                    
                }else{
                    setCheckSaveDNI(true);
                    console.log("SI puede guardar por DNI")
                }
            });
        } catch (error) {
            console.log(error)
        }
    }
    
    const openModalResultCandidateSearch = () => {
        setVisibleModalResultCandidateSearch(!visibleModalResultCandidateSearch);
    }

    const handleChangeDecimal = (event) => {
        const { value } = event.target;
    
        // Expresión regular para permitir solo números y un punto opcional para los decimales
        const regex = /^\d*\.?\d*$/;
    
        if (regex.test(value) || value === '') {
          setSalary(value)
          // Llama a la función de cambio pasada como prop
          /*if (this.props.onChange) {
            this.props.onChange(value);
          }*/
        }
      };

    const tableColumnsCandidateSearch = [
        {
            name: 'Nombre',
            selector: 'name_complete',
            sortable: false,
            center: false,
        },
        {
            name: 'Teléfono',
            selector: 'phone',
            sortable: false,
            center: false,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: false,
            center: false,
        },
        {
            name: 'Nº. de Identidad',
            selector: 'number_identity',
            sortable: false,
            center: false,
        },
    ]

    return (
        <Fragment>
            <Breadcrumb parent="Candidatos" title="Agregar Candidato" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12" md="12">
                        <Card>
                            <CardHeader>
                                <Media>
                                    <img className="img-40 img-fluid m-r-20" src={one} alt="" />
                                    <Media body>
                                        <h4 className="f-w-600">{"Candidato"}</h4>
                                        <p>{"Ingresa los datos del Candidato"}</p>
                                    </Media>
                                </Media>
                            </CardHeader>
                            <CardBody>
                                <div className="job-description">
                                    <h5 className="mb-0">{"Datos Personales"} </h5>
                                    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <FormGroup>
                                                    <Label htmlFor="type_identity">{"Tipo de Documento"}:</Label>
                                                    <FormGroup className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                        <div className="radio radio-primary">
                                                            <Input id="type_identity_1" type="radio" name="type_identity" value="DNI" defaultChecked innerRef={register()} />
                                                            <Label className="mb-0" for="type_identity_1"><span className="digits"> {"DNI"}</span></Label>
                                                        </div>
                                                        <div className="radio radio-primary">
                                                            <Input id="type_identity_2" type="radio" name="type_identity" value="NIE" innerRef={register()} />
                                                            <Label className="mb-0" for="type_identity_2"><span className="digits"> {"NIE"}</span></Label>
                                                        </div>
                                                        <div className="radio radio-primary">
                                                            <Input id="type_identity_3" type="radio" name="type_identity" value="PASAPORTE" innerRef={register()} />
                                                            <Label className="mb-0" for="type_identity_3"><span className="digits"> {"PASAPORTE"}</span></Label>
                                                        </div>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12}>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="number_identity">{"Número de Documento"}:</Label>
                                                    <Input className="form-control" id="number_identity"
                                                        onBlur={handleChangeInputCandidateSearch}
                                                        name="number_identity" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="name">{"Nombre"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="name"
                                                        onBlur={handleChangeInputCandidateSearch}
                                                        name="name" type="text" placeholder="" required innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="lastname">{"Primer Apellido"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="lastname"
                                                        onBlur={handleChangeInputCandidateSearch}
                                                        name="lastname" type="text" placeholder="" required innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="second_lastname">{"Segundo Apellido"}</Label>
                                                    <Input className="form-control" id="second_lastname"
                                                        onBlur={handleChangeInputCandidateSearch}
                                                        name="second_lastname" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col sm="4">
                                                <div className="col-form-label pt-0">{"Fecha de Nacimiento"}</div>
                                                <FormGroup>
                                                    <DatePicker selected={startDate} className="form-control" onChange={handleChange} innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="nationality">{"Nacionalidad"}:</Label>
                                                    <Input className="form-control" id="nationality" name="nationality" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row> */}
                                        <h5 className="mb-0">{"Datos de Contacto"} </h5>

                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="phone">{"Teléfono"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="phone"
                                                        onBlur={handleChangeInputCandidateSearch}
                                                        name="phone" type="tel" placeholder="" required innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="email">{"Email"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="email"
                                                        onBlur={handleChangeInputCandidateSearch}
                                                        name="email" type="email" placeholder="" required innerRef={register()} />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="city">{"Provincia"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="city" name="city" type="text" required placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="address">{"Dirección"}:</Label>
                                                    <Input className="form-control" id="address" name="address" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="postal_code">{"Código Postal"}:</Label>
                                                    <Input className="form-control" id="postal_code" name="postal_code" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                            </Col>
                                        </Row>

                                        <h5 className="mb-0">{"Formacion"} </h5>

                                        <Row>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Grado de Instruccion"}:</div>
                                                <FormGroup>
                                                    <Select
                                                        onChange={handleSelectGrade}
                                                        options={[
                                                            { value: 'Grado Medio', label: 'Grado Medio' },
                                                            { value: 'Grado Superior', label: 'Grado Superior' }
                                                        ]}
                                                        id="grade" name="grade"
                                                        innerRef={register({ required: true })} />

                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Formación Técnica"}:</div>
                                                <FormGroup>
                                                    <Select
                                                        onChange={handleSelectForma}
                                                        options={listAcademic}
                                                        onInputChange={handleEducationChange}
                                                        id="formation_tec" name="formation_tec"
                                                        innerRef={register({ required: true })} />

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="title">{"Titulo Obtenido"}:</Label>
                                                    <Input className="form-control" id="title" name="title" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="institute">{"Instituto / Universidad"}:</Label>
                                                    <Input className="form-control" id="institute" name="institute" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h5 className="mb-0">{"Skills"}</h5>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Categoría/Rol"}:</div>
                                                <FormGroup>
                                                    <Select
                                                        onChange={handleSelectCategory}
                                                        options={[
                                                            { value: 'Becario', label: 'Becario' },
                                                            { value: 'Junior', label: 'Junior' },
                                                            { value: 'Mid', label: 'Mid' },
                                                            { value: 'Senior', label: 'Senior' }
                                                        ]}
                                                        id="category" name="category"
                                                        innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Idiomas"}:</div>
                                                <FormGroup>
                                                    <Typeahead
                                                        id="languages"
                                                        labelKey="languages"
                                                        onChange={handleSelectLang}
                                                        multiple={true}
                                                        options={listLanguageDataShow}
                                                        placeholder=""
                                                        innerRef={register()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="col-form-label pt-0">{"Tecnologías"}:<span className="font-danger"></span></div>
                                        <TechnologyTypeahead onTechnologySelect={handleTechnologySelection} />
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Salario"}:<span className="font-danger">*</span></div>
                                                <FormGroup>
                                                    <Input className="form-control" 
                                                    name="salary" type="decimal" 
                                                    min="1" step="any" 
                                                    placeholder="" 
                                                    value={salary}
                                                    required innerRef={register()}
                                                    onChange={handleChangeDecimal} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Tarifa Autónomo"}:</div>
                                                <FormGroup>
                                                    <Input className="form-control" 
                                                    name="salary_letters" 
                                                    type="text" placeholder=""  
                                                    innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h5 className="mb-0">{"Vacante"}</h5>
                                        <div className="col-form-label pt-0">{"Vacante"}:</div>
                                        <Row>
                                            <Col md={6} sm={12} className="align-self-center">
                                                <FormGroup>
                                                    <Typeahead
                                                        id="id"
                                                        ref={typeaheadRef}
                                                        labelKey="name"
                                                        multiple={true}
                                                        selected={listPositionData.filter(item => multiplePosition.includes(item.id))}
                                                        onChange={handleSelectPosition}
                                                        options={listPositionData}
                                                        placeholder=""
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Button color="primary mr-1" onClick={handleToggleVisibleAddPosition}>
                                                    <span><i className="fa fa-plus text-white"></i>{" "}</span>
                                                    {"Agregar nueva vacante"}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <div className={`content ${visibleAddPosition ? 'visible' : 'hidden'}`}>
                                            <Row>
                                                <Col>
                                                    <div className="col-form-label pt-0">{"Cliente/Portal"}:<span className="font-danger">*</span></div>
                                                    <FormGroup>
                                                        <Select
                                                            onChange={(element) => setClientValue(element.value)}
                                                            options={listClientsPortalData} id="client" name="client"
                                                            menuPortalTarget={document.body}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <div className="col-form-label pt-0">{"Código de Vacante."}:<span className="font-danger">*</span></div>
                                                    <FormGroup>
                                                        <Input
                                                            name='code_position'
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(event) => setPositionCode(event.target.value)}
                                                            value={positionCode}
                                                            onBlur={handleChangeInputSearch}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <div className="col-form-label pt-0">{"Nombre de Vacante."}:<span className="font-danger">*</span></div>
                                                    <FormGroup>
                                                        <Input
                                                            name='name_position'
                                                            className="form-control"
                                                            type="text"
                                                            onChange={(event) => setPositionName(event.target.value)}
                                                            value={positionName}
                                                            onBlur={handleChangeInputSearch}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="align-self-center">
                                                    <Button color="primary mr-1" onClick={handleAddPosition}>
                                                        <span><i className="fa fa-plus text-white"></i></span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                        <h5 className="mb-0">{"Documentos"}</h5>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Adjuntar CV"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" type="file" name="CV" required innerRef={register()} multiple/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                            </Col>
                                        </Row>
                                        <h5 className="mb-0">{"Extra"}</h5>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Perfil de Linkedin"}:</Label>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend"><InputGroupText>{<i className="fa fa-linkedin font-primary"></i>}</InputGroupText></InputGroupAddon>
                                                        <Input className="form-control" type="text" name="linkedin" placeholder="" innerRef={register()} />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <FormGroup>
                                                    <Label htmlFor="origin">{"Origen"}:<span className="font-danger">*</span></Label>
                                                    <Select
                                                        onChange={handleSelectOrigin}
                                                        options={listOriginShow}
                                                        id="origin" name="origin" required
                                                        innerRef={register({ required: true })} />
                                                </FormGroup>
                                            </Col>
                                            {/*
                                            <Col md={4} sm={12}>
                                                <FormGroup>
                                                    <Label htmlFor="main_market">{"Tipo de Perfil"}:</Label>
                                                    <Select
                                                        onChange={handleSelectMainMarket}
                                                        options={listMainMarketShow}
                                                        id="main_market" name="main_market"
                                                        innerRef={register({ required: true })} />
                                                </FormGroup>
                                            </Col>


                                             <Col>
                                                <FormGroup check>
                                                    <div className="checkbox checkbox-primary">
                                                        <Input id="inline-form-2" type="checkbox" />
                                                        <Label className="mb-0" htmlFor="inline-form-2">{"Es Portal"}</Label>
                                                    </div> 
                                                </FormGroup>
                                            </Col> */}
                                        </Row>

                                        <Row>
                                            <Col md={6} sm={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Observación / Comentarios"}:<span className="font-danger">*</span></Label>
                                                    <Input type="textarea" required className="form-control btn-square" name="description" rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Comentario para enviar al cliente"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="comment_client" rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="card-footer">
                                            <Button disabled={disabledButton} color="primary mr-1" type="submit">{"Agregar Candidato"}</Button>
                                            <Button type="button" color="light">{Cancel}</Button>
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal size="lg" isOpen={visibleModalResultSearch} toggle={openModalResultSearch}>
                    <ModalHeader toggle={openModalResultSearch}>
                        {"Pueda que la vacante ya esté dentro del sistema."}
                    </ModalHeader>
                    <ModalBody>
                        {"Si la vacante aparece en esta lista, haz clic en su nombre para agregarla al candidato"}
                        <DataTable
                            key={"id"}
                            data={dataResultSearch}
                            columns={tableColumnsSearch}
                            striped={true}
                            center={true}
                            persistTableHead
                            onRowClicked={handleRowClick}
                        />
                    </ModalBody>
                </Modal>
                <Modal size="lg" isOpen={visibleModalResultCandidateSearch} toggle={openModalResultCandidateSearch}>
                    <ModalHeader toggle={openModalResultCandidateSearch}>
                        {"Pueda que tu candidato ya este dentro del sistema."}
                    </ModalHeader>
                    <ModalBody>
                        {"Si tu candidato aparece en esta lista, haz clic en su nombre para mirar su historial"}
                        <DataTable
                            data={dataResultCandidateSearch}
                            columns={tableColumnsCandidateSearch}
                            striped={true}
                            center={true}
                            persistTableHead
                        />
                    </ModalBody>
                </Modal>
            </Container>
        </Fragment>
    );
};

export default AddCandidate;